import { homeModel } from "../model/homeModel";
import { LoginModel } from "../model/loginModel";

export default class homeController {

  constructor() {
    this.totalProducts = document.getElementById("totalProducts");
  }

  init() {
    this.setupEventListeners();
    this.loadProductAmount();
    this.loadCategoryAmount();
    this.loadUser();
  }

  setupEventListeners() {
    document.getElementById('firstLetter').addEventListener('click', this.toggleLogout.bind(this));
    document.getElementById('logoutButton').addEventListener('click', this.logout.bind(this));
  }

  loadUser() {
    LoginModel.getInitials().then(data => {
      document.getElementById('firstLetter').innerText = data.initials;
    });
    LoginModel.getUsername().then(data => {
      document.getElementById('firstName').innerText = data.name;
    });
  }

  logout() {
    localStorage.removeItem('jwt');
    window.location.href = '/login';
  }

  toggleLogout() {
    document.getElementById('logoutButton').classList.toggle('hidden');
  }

  loadProductAmount() {
    homeModel.fetchProductAmount().then(data => {
      this.totalProducts.innerHTML = data.amount
    })
  }

  loadCategoryAmount() {
    homeModel.fetchCategoryAmount().then(data => {
      document.getElementById('totalCategories').innerHTML = data.amount
    })
  }
}