import { LoginModel } from '../model/loginModel.js';

export default class loginController {
  constructor() {
    this.EMAIL = document.getElementById('email');
    this.PASSWORD = document.getElementById('password');
    this.EMAILERROR = document.getElementById('emailError');
    this.PASSWORDERROR = document.getElementById('passwordError');
  }

  init() {
    this.setupEventListeners();
    this.loadUser();
  }

  setupEventListeners() {
    document.querySelector('form').addEventListener('submit', (e) => {
      e.preventDefault();
      this.clearLogin();
      if (this.validateLogin()) {
        const user = {
          email: this.EMAIL.value,
          password: this.PASSWORD.value,
        };
        console.log("ik kom hier nog")
        LoginModel.login(user)
          .then(response => {
            console.log(response);
            if (response.token != null) {
              localStorage.setItem('jwt', response.token);
              window.location.href = '/';
            } else {
              this.EMAILERROR.innerText = "Invalid email or password";
              this.EMAIL.style.border = '1px solid red';
              this.PASSWORD.style.border = '1px solid red';

            }
          });
      }
    });
  }

  loadUser() {
    LoginModel.getInitials().then(data => {
      document.getElementById('firstLetter').innerText = data.initials;
    });
  }

  validateLogin() {
    let status = true;
    if (this.EMAIL.value === '') {
      this.EMAILERROR.innerText = 'Email is required';
      this.EMAIL.style.border = '1px solid red';
      status = false;
    }
    if (this.PASSWORD.value === '') {
      this.PASSWORDERROR.innerText = 'Password is required';
      this.PASSWORD.style.border = '1px solid red';
      status = false;
    }
    return status;
  }

  clearLogin() {
    this.EMAILERROR.innerText = '';
    this.EMAIL.style.border = '1px solid #dadada';
    this.PASSWORDERROR.innerText = '';
    this.PASSWORD.style.border = '1px solid #dadada';
  }
}