export const LoginModel = {
  async login(user) {
    const response = await fetch('/api/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(user),
    });
    return await response.json();
  },

  async logout() {
    const response = await fetch('/api/login/logout', {
      method: 'POST',
    });
    return await response.json();
  },

  async verifyLoggedin() {
    const jwt = localStorage.getItem('jwt');
    console.log(jwt);
    if (!jwt) {
      return false;
    }

    const response = await fetch('/api/login/verify', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${jwt}`,
      },
    });

    if (response.status === 200) {
      return true;
    } else {
      return false;
    }
  },

  async getUsername() {
    const jwt = localStorage.getItem('jwt');
    const response = await fetch('api/login/getUser', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${jwt}`,
      },
    });
    return await response.json();
  },

  async getInitials() {
    const jwt = localStorage.getItem('jwt');
    const response = await fetch('/api/login/getInitials', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${jwt}`,
      }
    });
    return await response.json();
  }
};
