import { CategoryModel } from "../model/categoryModel";
import { LoginModel } from "../model/loginModel";

export default class categoryController {

  constructor() {
    this.page = 1;
    this.categoryId;
    this.categoryName;
    this.NAME = document.getElementById('name');
    this.NAMEERROR = document.getElementById('nameError');
    this.searchInput = document.getElementById('searchInput');
    this.productId;
  }

  init() {
    this.setupEventListeners();
    this.loadCategories();
    this.paginationForCategories();
    this.loadUser();
  }

  setupEventListeners() {
    document.getElementById('firstLetter').addEventListener('click', this.toggleLogout.bind(this));
    document.getElementById('createCategory').addEventListener('click', this.toggleCreateCategory.bind(this));
    document.getElementById('closeCategoryModel').addEventListener('click', this.toggleCreateCategory.bind(this));
    document.getElementById('exitDeleteCategoryButton').addEventListener('click', this.toggleDeleteCategory.bind(this));
    document.getElementById('cancelDeleteCategory').addEventListener('click', this.toggleDeleteCategory.bind(this));
    document.getElementById('deleteCategoryButton').addEventListener('click', this.deleteCategory.bind(this));
    document.getElementById('searchInput').addEventListener('keyup', this.searchCategories.bind(this));
    document.getElementById('productSearchInput').addEventListener('keyup', this.searchProducts.bind(this));
    document.getElementById('categoryProductSearchInput').addEventListener('keyup', this.searchCategoryProducts.bind(this));
    document.getElementById('exitEditCategoryButton').addEventListener('click', this.toggleEditCategory.bind(this));
    document.getElementById('logoutButton').addEventListener('click', this.logout.bind(this));
    document.querySelector('form').addEventListener('submit', (e) => {
      e.preventDefault();
      this.clearCategory();
      if (this.validateCategory()) {
        let category;
        category = {
          name: this.NAME.value,
        };
        this.createCategory(category);

      }
    });
  }

  loadUser() {
    LoginModel.getInitials().then(data => {
      document.getElementById('firstLetter').innerText = data.initials;
    });
  }

  logout() {
    localStorage.removeItem('jwt');
    window.location.href = '/login';
  }

  createCategory(category) {
    CategoryModel.createCategory(category)
      .then(data => {
        if (data.error) {
          if (data.error.string === 'Product already exists.') {
            this.NAMEERROR.innerText = 'Product name already exists.';
            this.NAME.style.border = '1px solid red';
          }
        } else {
          this.toggleCreateCategory();
          this.NAME.value = '';
          window.location.reload();
        }
      });
  }


  toggleCreateCategory() {
    document.getElementsByClassName('categoryModel')[0].classList.toggle('hidden');
  }
  toggleLogout() {
    document.getElementById('logoutButton').classList.toggle('hidden');
  }

  loadCategories() {
    CategoryModel.getCategories(this.page)
      .then(data => {
        this.generateCategoryList(data);
      });
  }

  toggleDeleteCategory() {
    document.getElementsByClassName('deleteCategory')[0].classList.toggle('hidden');
  }

  clearCategory() {
    this.NAMEERROR.innerText = '';
    this.NAME.style.border = '1px solid #dadada';
  }

  deleteCategory() {
    CategoryModel.deleteCategory(this.categoryId).then(() => {
      window.location.reload();
    });
  }

  searchCategories() {
    let searchString = this.searchInput.value;
    if (searchString === '') {
      this.loadCategories();
      return;
    }
    CategoryModel.searchGetProducts(this.categoryId, searchString).then(data => {
      this.generateCategoryList(data);
    });
  }

  searchCategoryProducts() {
    let searchString = document.getElementById('categoryProductSearchInput').value;
    if (searchString === '') {
      CategoryModel.getProductsByCategory(this.categoryId).then(data => {
        this.generateCoupledProductList(data);
      });
      return;
    }
    CategoryModel.searchGetProductsByCategory(this.categoryId, searchString).then(data => {
      this.generateCoupledProductList(data);
    });
  }

  searchProducts() {
    let searchString = document.getElementById('productSearchInput').value;
    if (searchString === '') {
      CategoryModel.getProducts(this.categoryId).then(data => {
        this.generateProductList(data);
      });
      return;
    }
    CategoryModel.searchGetProducts(this.categoryId, searchString).then(data => {
      this.generateProductList(data);
    });
  }



  toggleEditCategory() {
    document.getElementsByClassName('editCategory')[0].classList.toggle('hidden');
    CategoryModel.getProductsByCategory(this.categoryId).then(data => {
      this.generateCoupledProductList(data);
    });
    CategoryModel.getProducts(this.categoryId).then(data => {
      this.generateProductList(data);
    });
  }

  addProductToCategory() {
    CategoryModel.addProductToCategory(this.categoryId, this.productId).then(() => {
      CategoryModel.getProductsByCategory(this.categoryId).then(data => {
        this.generateCoupledProductList(data);
      });
      CategoryModel.getProducts(this.categoryId).then(data => {
        this.generateProductList(data);
      });
    });
  }

  removeProductFromCategory() {
    CategoryModel.removeProductFromCategory(this.categoryId, this.productId).then(() => {
      CategoryModel.getProductsByCategory(this.categoryId).then(data => {
        this.generateCoupledProductList(data);
      });
      CategoryModel.getProducts(this.categoryId).then(data => {
        this.generateProductList(data);
      });
    });
  }


  generateCoupledProductList(products) {
    const productContainer = document.getElementById('categoryProducts');
    productContainer.innerHTML = ''; // Clear existing products

    products.forEach(product => {
      const productDiv = document.createElement('div');

      const productName = document.createElement('h4');
      productName.textContent = product.name;
      productDiv.appendChild(productName);

      const removeButton = document.createElement('button');
      removeButton.id = "removeProduct"; // Consider using unique IDs if multiple products
      removeButton.addEventListener('click', () => {
        this.productId = product.id;
        this.removeProductFromCategory();
      });

      const removeIcon = document.createElement('span');
      removeIcon.className = 'material-symbols-outlined';
      removeIcon.textContent = 'remove_circle';
      removeButton.appendChild(removeIcon);

      productDiv.appendChild(removeButton);

      productContainer.appendChild(productDiv);
    });
  }

  generateProductList(products) {
    const productContainer = document.getElementById('productBox');
    productContainer.innerHTML = ''; // Clear existing products

    products.forEach(product => {
      const productDiv = document.createElement('div');

      const productName = document.createElement('h4');
      productName.textContent = product.name;
      productDiv.appendChild(productName);

      const addButton = document.createElement('button');
      addButton.id = "addProduct"; // Consider using unique IDs if multiple products
      addButton.addEventListener('click', () => {
        this.productId = product.id;
        this.addProductToCategory();
      });

      const addIcon = document.createElement('span');
      addIcon.className = 'material-symbols-outlined';
      addIcon.textContent = 'add_circle';
      addButton.appendChild(addIcon);

      productDiv.appendChild(addButton);

      productContainer.appendChild(productDiv);
    });
  }

  generateCategoryList(categories) {
    const categoryContainer = document.getElementById('categoriesCard');
    categoryContainer.innerHTML = '';
    for (let category of categories) {
      const div = document.createElement('div');
      div.className = 'row';

      const pName = document.createElement('p');
      pName.textContent = category.name;
      div.appendChild(pName);

      const editButton = document.createElement('button');
      const editSpan = document.createElement('span');
      editSpan.className = 'material-symbols-outlined';
      editSpan.textContent = 'edit';
      editButton.appendChild(editSpan);
      editButton.id = "editCategoryButton";
      editButton.addEventListener('click', () => {
        this.categoryId = category.id;
        this.categoryName = category.name;
        this.toggleEditCategory();
      });
      div.appendChild(editButton);

      const deleteButton = document.createElement('button');
      deleteButton.id = "deleteCategoryButton";
      deleteButton.addEventListener('click', () => {
        this.categoryId = category.id;
        this.toggleDeleteCategory();
      });
      const deleteSpan = document.createElement('span');
      deleteSpan.className = 'material-symbols-outlined';
      deleteSpan.textContent = 'delete';
      deleteButton.appendChild(deleteSpan);
      div.appendChild(deleteButton);

      categoryContainer.appendChild(div);
    }
  }

  validateCategory() {
    let status = true;
    if (this.NAME.value === '') {
      this.NAMEERROR.innerText = 'Name is required';
      this.NAME.style.border = '1px solid red';
      status = false;
    }
    return status;
  }


  paginationForCategories() {
    let paginationDiv = document.getElementById('pagination');
    let pages;
    // Clearing the elements inside the paginationDiv
    while (paginationDiv.firstChild) {
      paginationDiv.removeChild(paginationDiv.firstChild);
    }
    CategoryModel.getPages().then(data => { // Use CategoryModel for category pagination
      pages = data.pages;
      if (this.page > 1) {
        const previousButton = document.createElement('button');
        previousButton.textContent = this.page - 1;
        previousButton.addEventListener('click', () => {
          this.page--;
          this.loadCategories(); // Load categories instead of products
          this.paginationForCategories(); // Recursive call for updated pagination
        });
        const currentPage = document.createElement('button');
        currentPage.textContent = this.page;
        currentPage.classList.add('active');
        if (pages > this.page) {
          const nextButton = document.createElement('button');
          nextButton.textContent = this.page + 1;
          nextButton.addEventListener('click', () => {
            this.page++;
            this.loadCategories(); // Load categories instead of products
            this.paginationForCategories(); // Recursive call for updated pagination
          });
          paginationDiv.appendChild(previousButton);
          paginationDiv.appendChild(currentPage);
          paginationDiv.appendChild(nextButton);
        } else {
          paginationDiv.appendChild(previousButton);
          paginationDiv.appendChild(currentPage);
        }
      } else {
        const currentPage = document.createElement('button');
        currentPage.textContent = this.page;
        currentPage.classList.add('active');
        if (pages > this.page) {
          const nextButton = document.createElement('button');
          nextButton.textContent = this.page + 1;
          nextButton.addEventListener('click', () => {
            this.page++;
            this.loadCategories(); // Load categories instead of products
            this.paginationForCategories(); // Recursive call for updated pagination
          });
          paginationDiv.appendChild(currentPage);
          paginationDiv.appendChild(nextButton);
        } else {
          paginationDiv.appendChild(currentPage);
        }
      }
    })
  }
}