import { ProductModel } from '../model/productModel.js';
import { LoginModel } from '../model/loginModel.js';

export default class ProductController {
  constructor() {
    this.NAME = document.getElementById('name');
    this.DESCRIPTION = document.getElementById('description');
    this.PRICE = document.getElementById('price');
    this.NAMEERROR = document.getElementById('nameError');
    this.DESCRIPTIONERROR = document.getElementById('descriptionError');
    this.PRICEERROR = document.getElementById('priceError');
    this.productId = null;
    this.page = 1;
    this.searchInput = document.getElementById('searchInput');
  }

  init() {
    this.setupEventListeners();
    this.loadProducts();
    this.pagination();
    this.loadUser();

  }

  loadUser() {
    LoginModel.getInitials().then(data => {
      document.getElementById('firstLetter').innerText = data.initials;
    });
  }

  setupEventListeners() {
    document.getElementById('createProductButton').addEventListener('click', this.toggleCreateProduct.bind(this));
    document.getElementById('')
    document.getElementById('exitCreateProductButton').addEventListener('click', this.toggleCreateProduct.bind(this));
    document.getElementById('firstLetter').addEventListener('click', this.toggleLogout.bind(this));
    document.getElementById('exitDeleteProductButton').addEventListener('click', this.toggleDeleteProduct.bind(this));
    document.getElementById('cancelDeleteProduct').addEventListener('click', this.toggleDeleteProduct.bind(this));
    document.getElementById('deleteProductButton').addEventListener('click', this.deleteProduct.bind(this));
    document.getElementById('searchInput').addEventListener('keyup', this.searchProducts.bind(this));
    document.getElementById('logoutButton').addEventListener('click', this.logout.bind(this));

    document.querySelector('form').addEventListener('submit', (e) => {
      e.preventDefault();
      if (this.productId !== null) {
        // Edit product
        this.clearProduct();
        if (this.validateProduct()) {
          let product;

          product = {
            id: this.productId,
            name: this.NAME.value,
            description: this.DESCRIPTION.value,
            price: parseFloat(this.PRICE.value),
          };

          this.editProduct(product);
        }
      } else {
        // we are going to create a product
        this.clearProduct();
        if (this.validateProduct()) {
          let product;
          product = {
            name: this.NAME.value,
            description: this.DESCRIPTION.value,
            price: parseFloat(this.PRICE.value),
          };
          this.createProduct(product);
        }
      }
    });
  }

  logout() {
    localStorage.removeItem('jwt');
    window.location.href = '/login';
  }


  createProduct(product) {
    ProductModel.createProduct(product)
      .then(data => {
        if (data.error) {
          if (data.error.string === 'Product already exists.') {
            this.NAMEERROR.innerText = 'Product name already exists.';
            this.NAME.style.border = '1px solid red';
          }
        } else {
          this.toggleCreateProduct();
          this.NAME.value = '';
          this.DESCRIPTION.value = '';
          this.PRICE.value = '';
          window.location.reload();
        }
      });
  }

  editProduct(product) {
    ProductModel.editProduct(product)
      .then(data => {
        if (data.error) {
          if (data.error.string === 'Product already exists.') {
            this.NAMEERROR.innerText = 'Product name already exists.';
            this.NAME.style.border = '1px solid red';
          }
        } else {
          this.toggleCreateProduct();
          this.NAME.value = '';
          this.DESCRIPTION.value = '';
          this.PRICE.value = '';
          window.location.reload();
        }
      });
  }

  toggleLogout() {
    document.getElementById('logoutButton').classList.toggle('hidden');
  }

  toggleCreateProduct() {
    document.getElementsByClassName('productModel')[0].classList.toggle('hidden');
    document.getElementById('modelTitel').innerHTML = "Create product";
    this.NAME.value = null;
    this.DESCRIPTION.value = null;
    this.PRICE.value = null;
    this.productId = null;
  }

  toggleDeleteProduct() {
    document.getElementsByClassName('deleteProduct')[0].classList.toggle('hidden');
  }

  toggleEditProduct() {
    document.getElementsByClassName('productModel')[0].classList.toggle('hidden');
    document.getElementById('modelTitel').innerHTML = "Edit product";
    this.NAME.value = this.productName;
    this.DESCRIPTION.value = this.productDescription;
    this.PRICE.value = this.productPrice;
    console.log(this.productPrice)
  }

  validateProduct() {
    let status = true;
    if (this.NAME.value === '') {
      this.NAMEERROR.innerText = 'Name is required';
      this.NAME.style.border = '1px solid red';
      status = false;
    }
    if (this.DESCRIPTION.value === '') {
      this.DESCRIPTIONERROR.innerText = 'Description is required';
      this.DESCRIPTION.style.border = '1px solid red';
      status = false;
    }
    if (this.PRICE.value === '') {
      this.PRICEERROR.innerText = 'Price is required';
      this.PRICE.style.border = '1px solid red';
      status = false;
    }
    return status;
  }

  clearProduct() {
    this.NAMEERROR.innerText = '';
    this.NAME.style.border = '1px solid #dadada';
    this.DESCRIPTIONERROR.innerText = '';
    this.DESCRIPTION.style.border = '1px solid #dadada';
    this.PRICEERROR.innerText = '';
    this.PRICE.style.border = '1px solid #dadada';
  }

  loadProducts() {
    ProductModel.getProducts(this.page)
      .then(data => {
        this.generateProductList(data);
      });
  }

  deleteProduct() {
    ProductModel.deleteProduct(this.productId).then(() => {
      window.location.reload();
    });
  }

  pagination() {
    let paginationDiv = document.getElementById('pagination');
    let pages;
    // we are delete the elements inside the paginationDiv
    while (paginationDiv.firstChild) {
      paginationDiv.removeChild(paginationDiv.firstChild);
    }
    ProductModel.getPages().then(data => {
      pages = data.pages;
      if (this.page > 1) {
        const previousButton = document.createElement('button');
        previousButton.textContent = this.page - 1;
        previousButton.addEventListener('click', () => {
          this.page--;
          this.loadProducts();
          this.pagination();
        });
        const currentPage = document.createElement('button');
        currentPage.textContent = this.page;
        currentPage.classList.add('active');
        if (pages > this.page) {
          const nextButton = document.createElement('button');
          nextButton.textContent = this.page + 1;
          nextButton.addEventListener('click', () => {
            this.page++;
            this.loadProducts();
            this.pagination();
          });
          paginationDiv.appendChild(previousButton);
          paginationDiv.appendChild(currentPage);
          paginationDiv.appendChild(nextButton);
        } else {
          paginationDiv.appendChild(previousButton);
          paginationDiv.appendChild(currentPage);
        }
      } else {
        const currentPage = document.createElement('button');
        currentPage.textContent = this.page;
        currentPage.classList.add('active');
        if (pages > this.page) {
          const nextButton = document.createElement('button');
          nextButton.textContent = this.page + 1;
          nextButton.addEventListener('click', () => {
            this.page++;
            this.loadProducts();
            this.pagination();
          });
          paginationDiv.appendChild(currentPage);
          paginationDiv.appendChild(nextButton);
        } else {
          paginationDiv.appendChild(currentPage);
        }
      }
    })
  }

  searchProducts() {
    let searchString = this.searchInput.value;
    if (searchString === '') {
      this.loadProducts();
      return;
    }
    ProductModel.searchProducts(searchString).then(data => {
      this.generateProductList(data);
    });
  }


  generateProductList(products) {
    const container = document.getElementById('productsCard');
    container.innerHTML = ''; // Clear existing products
    for (let product of products) {
      const div = document.createElement('div');
      div.className = 'row';

      const pName = document.createElement('p');
      pName.textContent = product.name;
      div.appendChild(pName);

      const pDescription = document.createElement('p');
      pDescription.textContent = product.description;
      div.appendChild(pDescription);

      const pPrice = document.createElement('p');
      pPrice.textContent = `€ ${product.price.toFixed(2)}`;
      div.appendChild(pPrice);

      const editButton = document.createElement('button');
      const editSpan = document.createElement('span');
      editSpan.className = 'material-symbols-outlined';
      editSpan.textContent = 'edit';
      editButton.appendChild(editSpan);
      editButton.id = "editProductButton";
      editButton.addEventListener('click', () => {
        this.productId = product.id;
        this.productName = product.name;
        this.productDescription = product.description;
        this.productPrice = product.price.toFixed(2)
        this.toggleEditProduct();
      });
      div.appendChild(editButton);

      const deleteButton = document.createElement('button');
      deleteButton.id = "deleteProductButton";
      deleteButton.addEventListener('click', () => {
        this.productId = product.id;
        this.toggleDeleteProduct();
      });
      const deleteSpan = document.createElement('span');
      deleteSpan.className = 'material-symbols-outlined';
      deleteSpan.textContent = 'delete';
      deleteButton.appendChild(deleteSpan);
      div.appendChild(deleteButton);

      container.appendChild(div);
    }
  }

}