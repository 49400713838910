export const homeModel = {
  async fetchProductAmount() {
    const response = await fetch('/api/products/amount', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return await response.json();
  },

  async fetchCategoryAmount() {
    const response = await fetch('/api/categories/amount', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return await response.json();
  }
}