import page from 'page'
import ProductController from './controller/productController.js';
import loginController from './controller/loginController.js';
import homeController from './controller/homeController.js';
import categoryController from './controller/categoryController.js';
import { LoginModel } from './model/loginModel.js';


function loadContent(url, callback) {
  if (url != '../src/views/login.html') {
    LoginModel.verifyLoggedin().then((result) => {
      if (!result) {
        console.log('not logged in');
        page.redirect('/login');
      }
    }).catch(() => {
      console.log('not logged in');
      page.redirect('/login');
    });
  }
  fetch(url)
    .then(response => response.text())
    .then(html => {
      document.getElementById('app').innerHTML = html;
      if (callback) {
        callback();
      }
    });
}

// define routes
page('/', () => {
  loadContent('../src/views/home.html', () => {
    const HomeController = new homeController();
    HomeController.init();
  });
})

page('/login', () => {
  loadContent('../src/views/login.html', () => {
    const LoginController = new loginController();
    LoginController.init();
  });
})

page('/products', () => {
  loadContent('../src/views/products.html', () => {
    const productController = new ProductController();
    productController.init();
  });
})

page('/categories', () => {
  loadContent('../src/views/categories.html', () => {
    const CategoryController = new categoryController();
    CategoryController.init();
  });
})

page('/offers', () => {
  loadContent('../src/views/offers.html')
})

page();