export const CategoryModel = {

  async getCategories(page) {
    const response = await fetch(`/api/categories?page=${page}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return await response.json();
  },

  async getPages() {
    const response = await fetch(`/api/categories/pages`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return await response.json();
  },

  async createCategory(category) {
    const response = await fetch(`/api/categories`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(category),
    });
    return await response.json();
  },

  async deleteCategory(id) {
    const response = await fetch(`/api/categories/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return await response.json();
  },

  async searchCategories(searchString) {
    const response = await fetch(`/api/categories/${searchString}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return await response.json();
  },

  async getProductsByCategory(categoryId) {
    const response = await fetch(`/api/categories/${categoryId}/coupledProducts`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return await response.json();
  },

  async getProducts(categoryId) {
    const response = await fetch(`/api/categories/${categoryId}/products`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return await response.json();
  },

  async addProductToCategory(categoryId, productId) {
    const response = await fetch(`/api/categories/addProduct/${categoryId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ productId }), // Assuming the API expects an object with productId
    });
    if (response.ok) {
      Promise.resolve();
    }
  },

  async removeProductFromCategory(categoryId, productId) {
    const response = await fetch(`/api/categories/removeProduct/${categoryId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ productId }), // Assuming the API expects an object with productId
    });
    if (response.ok) {
      Promise.resolve();
    }
  },

  async searchGetProductsByCategory(categoryId, searchString) {
    const response = await fetch(`/api/categories/${categoryId}/coupledProducts/${searchString}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return await response.json();
  },

  async searchGetProducts(categoryId, searchString) {
    const response = await fetch(`/api/categories/${categoryId}/products/${searchString}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return await response.json();
  },


};
